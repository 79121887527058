import '../../new-stock.scss'
import { Button, Modal, notification, Spin } from 'antd'
import Identification, {
  FormStateInterface,
} from '../Identification/Identification'
import { MODE } from '../../../../../enum/mode'
import Packing from '../../Packing'
import Fournisseurs from '../Fournisseurs/Fournisseurs'
import Gestion, { FormGestionStateInterface } from '../Gestion/Gestion'
import Commentaire, {
  FormCommentaireStateInterface,
} from '../Commentaire/Commentaire'
import React, { ReactNode, useEffect, useState } from 'react'
import { DataTableParam } from '../model'
import { cloneDeep } from 'lodash'
import {
  Conditionnement,
  ReferenceRequest,
  RefFournisser,
} from '../../../../../models'
import Define from '../../../../../constants/define'
import referenceApi from '../../../../../http/referenceApi'
import SUCCESS from '../../../../../assets/images/success.png'
import CLOSE_ICON from '../../../../../assets/images/close-success.png'
import ERROR from '../../../../../assets/images/error.png'
import { focusById } from '../../../../../hook/usePressTab'
import { CommonError } from '../../../../../models/common/error'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { getCurrentFiltersText } from '../../../../../utils'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function StockCreate() {
  const [errorIdentification, setErrorIdentification] = useState<CommonError[]>(
    []
  )
  const [errorGestion, setErrorGestion] = useState<CommonError[]>([])
  const stockKey = useSelector((state: RootState) => state.selector).data.stock

  const [identificationData, setIdentificationData] =
    useState<FormStateInterface>()
  const [commentaireData, setCommentaireData] =
    useState<FormCommentaireStateInterface>()
  const [gestionData, setGestionData] = useState<FormGestionStateInterface>()
  const [packingData, setPackingData] = useState<DataTableParam[][]>()
  const [fournisseursData, setFournisseursData] = useState<DataTableParam[][]>()
  const [isValid_identification, set_isValid_identification] =
    useState<boolean>(false)
  const [isValid_commentaire] = useState<boolean>(true)
  const [isValid_gestion, set_isValid_gestion] = useState<boolean>(true)
  const [isValid_packing, set_isValid_packing] = useState<boolean>(false)
  const [isValid_fournisseurs, set_isValid_fournisseurs] =
    useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [numberOfSubmit, setNumberOfSubmit] = useState<number>(0)
  const [isShowAlertPacking, setIsShowAlertPacking] = useState<boolean>(false)
  const [isShowAlertFournisseurs, setIsShowAlertFournisseurs] =
    useState<boolean>(false)
  const [isPackingInit, setIsPackingInit] = useState<boolean>(true)
  const [qteVariable, setQteVariable] = useState(false)

  const isValidAll =
    isValid_identification &&
    isValid_commentaire &&
    isValid_gestion &&
    isValid_packing &&
    isValid_fournisseurs

  useEffect(() => {
    let errorIdentificationClone = cloneDeep(errorIdentification)
    if (identificationData?.number) {
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) => !(item.name === 'number' && item.errorMessage === ' ')
      )
    }
    if (identificationData?.libelle_long) {
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) => !(item.name === 'libelle_long' && item.errorMessage === ' ')
      )
    }
    setErrorIdentification(errorIdentificationClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificationData])

  useEffect(() => {
    let errorGestionClone = cloneDeep(errorGestion)
    if (gestionData?.dluo_value) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dluo_value' && item.errorMessage === ' ')
      )
    }
    if (gestionData?.dlv_value) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dlv_value' && item.errorMessage === ' ')
      )
    }
    if (gestionData?.dlc_value) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dlc_value' && item.errorMessage === ' ')
      )
    }
    if (!gestionData?.dluo) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dluo_value' && item.errorMessage === ' ')
      )
    }
    if (!gestionData?.dlv) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dlv_value' && item.errorMessage === ' ')
      )
    }
    if (!gestionData?.dlc) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dlc_value' && item.errorMessage === ' ')
      )
    }

    setErrorGestion(errorGestionClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gestionData])

  useEffect(() => {
    let errorIdentificationClone = cloneDeep(errorIdentification)
    if (identificationData?.number) {
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) =>
          !(
            item.name === 'number' &&
            (item.errorMessage === 'Numéro déjà existant' ||
              item.errorMessage === ' ')
          )
      )
      setErrorIdentification(errorIdentificationClone)
    }
    if (identificationData?.libelle_long) {
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) => !(item.name === 'libelle_long' && item.errorMessage === ' ')
      )
      setErrorIdentification(errorIdentificationClone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificationData])

  useEffect(() => {
    setErrorIdentification([])
  }, [])

  useEffect(() => {
    if (!isValid_packing && numberOfSubmit > 0) {
      setIsShowAlertPacking(true)
    }
    if (isValid_packing && numberOfSubmit > 0) {
      setIsShowAlertPacking(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmit])

  useEffect(() => {
    if (!isValid_fournisseurs && numberOfSubmit > 0) {
      setIsShowAlertFournisseurs(true)
    }
    if (isValid_fournisseurs && numberOfSubmit > 0) {
      setIsShowAlertFournisseurs(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmit])

  const onBackToInitPacking = (isInit: boolean) => {
    setIsPackingInit(isInit)
  }

  const onChangeIdentification = (values: FormStateInterface) => {
    setIdentificationData(values)
  }

  const onCheckInValidIdentification = (isValid: boolean) => {
    set_isValid_identification(isValid)
  }

  const onCheckValidGestion = (isValid: boolean) => {
    set_isValid_gestion(isValid)
  }

  const onChangeDataCommentaire = (values: FormCommentaireStateInterface) => {
    setCommentaireData(values)
  }

  const onChangeGestion = (values: FormGestionStateInterface) => {
    setGestionData(values)
  }

  const onChangeDataPacking = (data: DataTableParam[][]) => {
    setPackingData(data)
  }
  const onCheckValidPacking = (isValid: boolean) => {
    set_isValid_packing(isValid)
  }

  const onChangeDataFournisseurs = (data: DataTableParam[][]) => {
    setFournisseursData(data)
  }
  const onCheckValidFournisseurs = (isValid: boolean) => {
    set_isValid_fournisseurs(isValid)
  }

  useEffect(() => {
    focusById(stockKey['n-input'])
  }, [])

  const onChangeQteVar = (isUseQteVar: boolean) => {
    setQteVariable(isUseQteVar)
  }

  const onSubmit = () => {
    setNumberOfSubmit(numberOfSubmit + 1)
    let errorIdentificationClone = cloneDeep(errorIdentification)
    if (!identificationData?.number) {
      errorIdentificationClone.push({
        name: 'number',
        errorMessage: ' ',
      })
    } else
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) => item.name !== 'number' && item.errorMessage !== ''
      )

    if (!identificationData?.libelle_long) {
      errorIdentificationClone.push({
        name: 'libelle_long',
        errorMessage: ' ',
      })
    } else
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) => item.name !== 'libelle_long' && item.errorMessage !== ''
      )
    setErrorIdentification(errorIdentificationClone)

    let errorGestionClone = cloneDeep(errorGestion)
    if (!gestionData?.dluo_value && gestionData?.dluo) {
      errorGestionClone.push({
        name: 'dluo_value',
        errorMessage: ' ',
      })
    } else
      errorGestionClone = errorGestionClone.filter(
        (item) => item.name !== 'dluo_value' && item.errorMessage !== ''
      )

    if (!gestionData?.dlc_value && gestionData?.dlc) {
      errorGestionClone.push({
        name: 'dlc_value',
        errorMessage: ' ',
      })
    } else
      errorGestionClone = errorGestionClone.filter(
        (item) => item.name !== 'dlc_value' && item.errorMessage !== ''
      )

    if (!gestionData?.dlv_value && gestionData?.dlv) {
      errorGestionClone.push({
        name: 'dlv_value',
        errorMessage: ' ',
      })
    } else
      errorGestionClone = errorGestionClone.filter(
        (item) => item.name !== 'dlv_value' && item.errorMessage !== ''
      )

    setErrorGestion(errorGestionClone)

    if (!identificationData?.number || !identificationData?.libelle_long) return
    if (!isValidAll) return
    const data: ReferenceRequest = {
      created_by: localStorage.getItem(Define.USERNAME) || '',
      modification_by: localStorage.getItem(Define.USERNAME) || '',
    }
    data.dluo = gestionData?.dluo || false
    if (gestionData?.dluo_value)
      data.dluo_value = Number(gestionData.dluo_value)
    data.dlv = gestionData?.dlv || false
    if (gestionData?.dlv_value) data.dlv_value = Number(gestionData?.dlv_value)
    data.dlc = gestionData?.dlc || false
    if (gestionData?.dlc_value) data.dlc_value = Number(gestionData?.dlc_value)
    data.gestion = gestionData?.gestion || false
    data.blocage_entree = gestionData?.blocage_entree || false
    data.blocage_sortie = gestionData?.blocage_sortie || false
    if (gestionData?.alerte_stock) data.alerte_stock = gestionData?.alerte_stock
    if (gestionData?.niveau_alerte)
      data.niveau_alerte = gestionData?.niveau_alerte
    data.variante = gestionData?.variante || false

    data.icpe = []
    data.matieresdangereuses = []
    data.photo = ''

    if (commentaireData?.com_chargement.comment) {
      data.ref_charement = commentaireData?.com_chargement
    }
    if (commentaireData?.com_livraison.comment) {
      data.ref_livraison = commentaireData?.com_livraison
    }
    if (commentaireData?.com_reception.comment) {
      data.ref_reception = commentaireData?.com_reception
    }

    const conditionnement: Conditionnement[] = []
    const ref_fournissers: RefFournisser[] = []
    if (packingData) {
      for (let i = 0; i < packingData.length; i++) {
        const line = packingData[i]
        const couche = Number(
          line.find((item) => item.name === 'couche')?.value
        )
        const hauteur = Number(
          line.find((item) => item.name === 'hauteur')?.value || ''
        )
        const icone = line.find((item) => item.name === 'icone')?.value || ''
        const largeur = Number(
          line.find((item) => item.name === 'largeur')?.value || ''
        )
        const longueur = Number(
          line.find((item) => item.name === 'longueur')?.value || ''
        )
        const niveau = Number(
          line.find((item) => item.name === 'niveau')?.value || ''
        )
        const no_codebarre =
          line.find((item) => item.name === 'code_barre')?.value || ''
        const nom = line.find((item) => item.name === 'nom')?.value || ''
        const poids_brut = Number(
          line.find((item) => item.name === 'poids_brut')?.value || ''
        )
        const poids_net = Number(
          line.find((item) => item.name === 'poids_net')?.value || ''
        )
        const poids_add = Number(
          line.find((item) => item.name === 'poids_add')?.value || ''
        )
        const rotation =
          line.find((item) => item.name === 'rotation')?.value || ''
        const support =
          line.find((item) => item.name === 'support')?.value || ''
        const type = Number(
          line.find((item) => item.name === 'type')?.value || ''
        )
        const type_de_codebarre =
          line.find((item) => item.name === 'typeCB')?.value || ''

        const att_enable =
          line.find((item) => item.name === 'att_enable')?.value !== '0'

        let mergeData: Conditionnement = {
          couche,
          hauteur,
          icone,
          largeur,
          longueur,
          niveau,
          no_codebarre,
          nom,
          poids_brut,
          poids_net,
          poids_add,
          rotation,
          support,
          type,
          type_de_codebarre,
          att_enable,
          qte_variable: qteVariable,
        }
        if (gestionData?.variante) {
          mergeData.variante =
            line.find((item) => item.name === 'variante')?.value || '00'
        }
        let qte =
          line.find((item) => item.name === 'qte_contenant')?.value || ''
        if (qte) mergeData.qte = Number(qte)
        if (!couche) delete mergeData['couche']
        conditionnement.push(mergeData)
      }
    }
    data.conditionnement = conditionnement

    data.nom = identificationData?.number.trim()
    data.libelle_long = identificationData?.libelle_long
    data.marque = identificationData?.marque
    data.langua_libelle = identificationData?.langua_libelle
    data.libelle_court = identificationData?.libelle_court
    data.famille = identificationData?.famille
    data.sous_famille = identificationData?.sous_famille
    data.origine = identificationData?.origine
    data.photo = identificationData.photo
    data.qte_variable = !data.conditionnement.some(
      (item) => item.qte && item.qte > 0
    )
    data.conditionnement = data.conditionnement.map((item) => {
      item.qte_variable = data.qte_variable
      return item
    })
    data.qte_variable = qteVariable
    data.created_by = localStorage.getItem('username') || ''
    data.creater_id = localStorage.getItem('user_id') || ''
    if (fournisseursData) {
      //set ref fournisseur data send to api if ref fournisseur empty
      let firstLine = fournisseursData[0].find(
        (item) => item.name === 'nom'
      )?.value
      if (fournisseursData.length === 1 && !firstLine) {
        ref_fournissers.length = 0
      } else
        for (let i = 0; i < fournisseursData.length; i++) {
          const line = fournisseursData[i]
          const fournisser_id = line.find((item) => item.name === 'nom')?.value
          const reference_fournisser = line.find(
            (item) => item.name === 'fournisseur'
          )?.value
          const blocage_entree = gestionData?.blocage_entree || false
          ref_fournissers.push({
            fournisser_id,
            reference_fournisser,
            blocage_entree,
            client_code_nom:
              localStorage?.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
          })
        }
      data.ref_fournissers = ref_fournissers
    }

    data.ref_fournissers =
      ref_fournissers.length > 0 ? ref_fournissers : undefined
    data.client_code_nom =
      localStorage?.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''

    setIsLoading(true)
    referenceApi
      .createReference(data)
      .then(() => {
        notification.open({
          className: 'noti noti-success',
          message: (
            <div className="flex items-center">
              <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
              Succès: Création enregistrée
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
        setErrorIdentification([])
        navigate(
          `/gestion-de-stock/stock?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
        )
      })
      .catch((error: any) => {
        if (
          error?.response?.data?.error?.includes(
            'rpc error: code = Unknown desc = error: data invalid Nom douplicated'
          )
        ) {
          const errorIdentificationClone = cloneDeep(errorIdentification)
          errorIdentificationClone.push({
            name: 'number',
            errorMessage: 'Numéro déjà existant',
          })
          setErrorIdentification(errorIdentificationClone)
        } else
          notification.open({
            className: 'noti noti-error',
            message: (
              <div className="flex items-center">
                <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                Une erreur s'est produite. Merci de réessayer ultérieurement
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const pageIndex = Number(searchParams.get('page-index'))
  const pageSize = Number(searchParams.get('page-size'))

  const handleClose = () => {
    navigate(
      `/gestion-de-stock/stock?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
    )
  }

  return (
    <Modal
      prefixCls="modal-new-stock"
      centered
      open={true}
      onCancel={handleClose}
      width={'90%'}
      className="border-solid border-secondary rounded-xl"
      footer={null}
      destroyOnClose={true}
    >
      <Spin spinning={isLoading} style={{ overflow: 'auto' }}>
        <div
          className="flex text-primary text-2xl font-bold items-baseline border-none pb-2 mb-4"
          style={{ borderBottom: '1px solid #B7B7B7' }}
        >
          <span className="mr-4 modal-title-heading-1">Création</span>
          <span className="modal-title-heading-2 mr-4">Référence</span>
        </div>
        <div className="overflow-y-auto main-content ">
          <Identification
            mode={MODE.CREATE}
            onChangeData={onChangeIdentification}
            onCheckValid={onCheckInValidIdentification}
            identificationError={errorIdentification}
          />
          <Packing
            mode={MODE.CREATE}
            qteVariable={qteVariable}
            onChangeData={onChangeDataPacking}
            onCheckValid={onCheckValidPacking}
            onBackToInit={onBackToInitPacking}
            onChangeQteVar={onChangeQteVar}
            alert={
              isShowAlertPacking && !isValid_packing ? (
                !isPackingInit ? (
                  alertNode('Veuillez valider toutes ces lignes suivantes')
                ) : (
                  alertNode('Veuillez ajouter un Conditionnement')
                )
              ) : (
                <></>
              )
            }
            enableVariante={gestionData?.variante}
          />
          <Fournisseurs
            mode={MODE.CREATE}
            onChangeData={onChangeDataFournisseurs}
            onCheckValid={onCheckValidFournisseurs}
            alert={
              isShowAlertFournisseurs && !isValid_fournisseurs ? (
                alertNode('Veuillez valider toutes ces lignes suivantes')
              ) : (
                <></>
              )
            }
            detailLength={packingData?.length || 0}
          />
          <Gestion
            mode={MODE.CREATE}
            onChangeData={onChangeGestion}
            onCheckValid={onCheckValidGestion}
            gestionError={errorGestion}
          />
          <Commentaire
            mode={MODE.CREATE}
            onChangeData={onChangeDataCommentaire}
          />
        </div>

        <div className="footer-content flex justify-end items-center">
          <Button
            id={stockKey['Sauvegarder']}
            data-previous-id={stockKey['Réception']}
            data-next-id={stockKey['n-input']}
            disabled={isLoading}
            role="button"
            className={`btn-submit-modal text-btn-submit-last-modal `}
            onClick={onSubmit}
          >
            Sauvegarder
          </Button>
        </div>
      </Spin>
    </Modal>
  )
}

const alertNode = (text: string): ReactNode => (
  <span
    style={{ fontSize: 12, color: 'red', marginLeft: 5 }}
  >{`(${text})`}</span>
)
